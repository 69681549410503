<template lang="pug">
.ds-dynamic-dropdown
  Dropdown(
    :label="label"
    :items="items"
    v-model="internalValue"
    :editMobile="editMobile"
    :layout="layout"
    :id="id"
  )
  template(v-for="item in items")
    slot(v-bind:name="item.value" v-if="$slots[item.value] && item.value === value")
</template>

<script>
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import designSystemMixin from '@/components/Elements/mixins/designSystem';

  export default {
    name: 'DynamicDropdown',
    components: { Dropdown },
    mixins: [designSystemMixin],
    props: {
      id: {
        type: String,
        required: true,
      },
      label: { type: String, required: false },
      items: { type: Array, default: () => [], required: true },
      value: { type: [String, Number], default: () => null },
      editMobile: { type: Boolean, default: false },
      layout: {
        type: String,
        default: 'row',
        options: ['row', 'col'],
        validator: (value) => {
          return ['row', 'col'].includes(value);
        },
      },
    },
    computed: {
      internalValue: {
        get() {
          return this.value;
        },
        set(selected) {
          this.$emit('input', selected);
        },
      },
    },
  };
</script>

<style scoped lang="sass"></style>
